<template>
  <v-row justify="center" class="my-1">
    <v-col cols="8" md="8" class=" py-1">
      <router-link :to="`/orange-reports/pending`">
        <v-card elevation="0" class="orange-reports-widget" v-if="show">
          <v-card-title class="my-1 py-1">
            Orange Reports
          </v-card-title>
          <v-card-subtitle class="pt-1 pb-2">
            Pending Approval
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="6" md="6">
                <v-img src="/media/assets/orange-report-picture.png"></v-img>
              </v-col>
              <v-col cols="5" md="5" class="text-center">
                <p class="text-h4 font-weight-black mb-1">
                  <v-progress-circular
                    v-if="orangeReportsLoading"
                    :indeterminate="orangeReportsLoading"
                    color="primary"
                  ></v-progress-circular>
                  <router-link
                    v-if="!orangeReportsLoading"
                    :to="getOrangeReportsPageUrl()"
                  >
                    {{ orangeReportsNumber }}
                  </router-link>
                </p>
                <v-img src="/media/assets/orange-right-arrow.png"></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
import {
  GET_QUERY,
  API_ORANGE_REPORTS,
  API_CUSTOMERS
} from "@/core/store/crud.module";

export default {
  name: "OrangeReportsWidget",
  data: () => ({
    orangeReportsNumber: 0,
    show: false,
    orangeReportsLoading: false,
    customerId: 0
  }),
  watch: {
    $route: async function(to) {
      // this.show = to.name !== "CustomerDetails";
      // console.log(to);
      if (to.name == "RedirectToCustomer") return;

      let newCustomerId = 0;
      if (to.params?.customerId) {
        newCustomerId = to.params?.customerId;
      }

      if (this.customerId != newCustomerId) {
        this.customerId = newCustomerId;
        this.orangeReportsNumber = 0;
        await this.getOrangeReportsNumber(this.customerId);
      }
    }
  },
  async mounted() {
    this.getShow();

    if (this.$route.to) {
      if (this.$route.to.params?.customerId) {
        this.customerId = this.$route.to.params?.customerId;
      }
    } else if (this.$route.from) {
      if (this.$route.from.params?.customerId) {
        this.customerId = this.$route.from.params?.customerId;
      }
    } else {
      if (this.$route.params?.customerId) {
        this.customerId = this.$route.params?.customerId;
      }
    }

    await this.getOrangeReportsNumber(this.customerId);
  },
  methods: {
    getOrangeReportsPageUrl() {
      if (this.customerId != 0 && this.customerId != 712473) {
        return `/customers/${this.customerId}/orange-reports`;
      }
      return "/orange-reports/pending";
    },
    getShow() {
      this.show = true;
      // if (this.$route.to) this.show = this.$route.to.name !== "CustomerDetails";
      // else if (this.$route.from)
      //   this.show = this.$route.from.name !== "CustomerDetails";
      // else this.show = this.$route.name !== "CustomerDetails";
      //console.log(this.$route);
    },
    // async getCustomers() {
    //   if (this.loading) return;

    //   this.loading = true;

    //   let self = this;
    //   await this.$store
    //     .dispatch(GET_QUERY, {
    //       listName: API_CUSTOMERS
    //     })
    //     .then(response => {
    //       if (response.status >= 200 || response.status <= 204) {
    //         let items = response.data;

    //         let promises = [];

    //         for (let i = 0; i < items.length; i++) {
    //           let ind = i;
    //           promises.push(
    //             new Promise(function() {
    //               self.getRecentOrangeReports(items[ind].aspireId);
    //             })
    //           );
    //         }

    //         Promise.all(promises).then(values => {
    //           self.orangeReportsNumber = 0;
    //           for (let i = 0; i < values.length; i++) {
    //             self.orangeReportsNumber += values[i].data.$values.length;
    //           }
    //         });
    //       }
    //       self.loading = false;
    //     });
    // },
    // async getRecentOrangeReports(customerId) {
    //   let reportsUrl = `${API_CUSTOMERS}/${customerId}/orangeReports`;
    //   await this.$store
    //     .dispatch(GET_QUERY, {
    //       listName: reportsUrl
    //     })
    //     .then(response => {
    //       if (response.status >= 200 || response.status <= 204) {
    //         return Promise.resolve(response.data.$values);
    //       } else {
    //         return Promise.resolve([]);
    //       }
    //     });
    // },
    async getOrangeReportsNumber(customerId) {
      this.orangeReportsLoading = true;

      //Customers/717529/orangeReports/

      let self = this;
      let reportsUrl = `${API_ORANGE_REPORTS}/forUser/0/numbers`;
      if (customerId != 0) {
        reportsUrl = `${API_CUSTOMERS}/${customerId}/OrangeReports/numbers`;
      }
      await this.$store
        .dispatch(GET_QUERY, {
          listName: reportsUrl
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.orangeReportsNumber = response.data.pending;
            // self.orangeReportsNumber = response.data.$values.filter(
            //   el => !el.isApproved
            // ).length;
          } else {
            self.orangeReportsNumber = 0;
          }
          this.orangeReportsLoading = false;
        })
        .catch(error => {
          // console.log("getOrangeReportsNumber", error)
          self.orangeReportsLoading = false;
          if (error.response && error.response.status >= 400) {
            self.orangeReportsNumber = 0;
          } else if (error.response.data) {
            self.orangeReportsNumber = 0;
          }
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~/src/assets/sass/custom/custom.scss";
.orange-reports-widget {
  border: solid 2px $orange;
}

.theme--light.v-card.orange-reports-widget {
  background-color: transparent;
}

.theme--light.v-card.orange-reports-widget::before {
  content: "";
  background-color: white;
  opacity: 0.8;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
</style>
