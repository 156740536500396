<template>
  <ul class="menu-nav orange-bg">
    <router-link
      to="/customers"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon-users"></i> -->
          <span class="menu-text">Customers</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/yn-team-directory"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon-users"></i> -->
          <span class="menu-text">Team Directory</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/service-requests"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon-users"></i> -->
          <span v-if="openRequests == 0" class="menu-text">
            Service Requests
          </span>
          <span v-else class="menu-text">
            Service Requests ({{ openRequests }})
          </span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/message-center"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon-users"></i> -->
          <span v-if="newMessages == 0" class="menu-text">
            Message Center
          </span>
          <span v-else class="menu-text">
            Message Center ({{ newMessages }})
          </span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/file-library"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Team Docs</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/settings"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon-users"></i> -->
          <span class="menu-text">Settings</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import {
  GET_QUERY,
  API_SERVICE_REQUESTS,
  API_MESSAGES
} from "@/core/store/crud.module";

export default {
  name: "KTMenu",
  data: () => ({
    openRequests: 0,
    newMessages: 0
  }),
  async mounted() {
    await this.getServiceRequestsNumber();
    await this.getMessagesNumber();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    async getServiceRequestsNumber() {
      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_SERVICE_REQUESTS}/User/0/numbers`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            let numbers = response.data;

            this.openRequests = numbers.open;
            // for (let i = 0; i < items.length; i++) {
            //   let elem1 = items[i];
            //   if (elem1.isOpen) {
            //     this.openRequests += 1;
            //   }
            // }
          }
        });
    },
    async getMessagesNumber() {
      this.$store
        .dispatch(GET_QUERY, {
          listName: `${API_MESSAGES}/numbers`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            // let items = response.data.$values;
            this.newMessages = response.data;
            // items.forEach(element => {
            //   if (element.isNew) {
            //     this.newMessages += 1;
            //   }
            // });
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/src/assets/sass/custom/custom.scss";
.orange-bg {
  background-color: $orange;
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active)
  > .menu-link
  span {
  color: white;
}
</style>
