<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div class="container-fluid d-flex align-items-center justify-end">
      <div cols="12" sm="12" md="2">
        <v-autocomplete
          v-model="customer"
          :loading="isLoading"
          :items="customers"
          :search-input.sync="searchQuery"
          item-text="communityName"
          item-value="aspireId"
          cache-items
          class="mx-4"
          flat
          hide-no-data
          hide-details
          label="Customer Quick Find"
          solo-inverted
          prepend-inner-icon="mdi-magnify"
          clearable
        ></v-autocomplete>
        <!-- <v-text-field
          v-model="searchQuery"
          label="Customer Quick Find"
          prepend-inner-icon="mdi-magnify"
        >
        </v-text-field> -->
        <!-- <KTSearcDefault></KTSearcDefault> -->
      </div>
      <!-- begin:: Header Menu -->
      <!-- <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="kt_header_menu_wrapper"
      >
        <div
          v-if="headerMenuEnabled"
          id="kt_header_menu"
          ref="kt_header_menu"
          class="header-menu header-menu-mobile"
          v-bind:class="headerMenuClasses"
        >
         
        </div>
      </div> -->
      <!-- end:: Header Menu -->
      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
// import KTSearcDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
// import KTMenu from "@/view/layout/header/Menu.vue";
import { API_CUSTOMERS, GET_PAGED } from "@/core/store/crud.module";

export default {
  name: "KTHeader",
  data: () => ({
    searchQuery: "",
    customers: [],
    customer: null,
    isLoading: false
  }),
  components: {
    KTTopbar
    // KTSearcDefault
    // KTMenu
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    this.getCustomers();
  },
  watch: {
    customer() {
      if (this.customer) {
        this.$router.push({
          name: "RedirectToCustomer",
          params: { customerId: this.customer }
        });
      } else {
        this.$router.push({
          name: "Customers"
        });
      }
    }
  },
  methods: {
    async getCustomers() {
      this.isLoading = true;
      let self = this;
      await this.$store
        .dispatch(GET_PAGED, {
          listName: API_CUSTOMERS,
          params: { customerStatus: "active", shortFormat: true }
          // ,
          // params: {
          //   // query: this.userQuery,
          //   // skip: this.options.itemsPerPage * (this.options.page - 1),
          //   // take:
          //   //   this.options.itemsPerPage === -1
          //   //     ? this.totalUsers
          //   //     : this.options.itemsPerPage,
          //   // sort: this.sort
          // }
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            let items = response.data.$values;
            self.customers = items;
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          self.isLoading = false;
        });
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>
