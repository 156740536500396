<template>
  <v-row justify="center" class="mt-1">
    <v-col cols="8" class="py-1">
      <v-card elevation="0" class="service-requests-widget" v-if="show">
        <v-card-title class="my-1 py-1 text-center">
          Service Requests
        </v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col
              cols="6"
              sm="6"
              md="6"
              class="text-center d-flex justify-center"
            >
              <router-link :to="getServiceRequestsPageUrl()">
                <div
                  class="pa-3 orange-bg rounded-circle d-flex align-center justify-center white--text"
                >
                  <span class="text-h5 font-weight-black">
                    <v-progress-circular
                      v-if="loading"
                      :indeterminate="loading"
                      color="primary"
                    ></v-progress-circular>
                    <span v-if="!loading">{{ openRequestsNumber }}</span>
                  </span>
                </div>
                <span>Open</span>
              </router-link>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="6"
              sm="6"
              md="6"
              class="text-center d-flex justify-center"
            >
              <router-link :to="getServiceRequestsPageUrl()">
                <div
                  class="pa-3 orange-bg rounded-circle d-flex align-center justify-center white--text"
                >
                  <span class="text-h5 font-weight-black">
                    <v-progress-circular
                      v-if="loading"
                      :indeterminate="loading"
                      color="primary"
                    ></v-progress-circular>
                    <span v-if="!loading">{{ inProcessRequestsNumber }}</span>
                  </span>
                </div>
                <span>In Process</span>
              </router-link>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col
              cols="6"
              sm="6"
              md="6"
              class="text-center d-flex justify-center"
            >
              <router-link :to="getServiceRequestsPageUrl()">
                <div
                  class="pa-3 orange-bg rounded-circle d-flex align-center justify-center white--text"
                >
                  <span class="text-h5 font-weight-black">
                    <v-progress-circular
                      v-if="loading"
                      :indeterminate="loading"
                      color="primary"
                    ></v-progress-circular>
                    <span v-if="!loading"> {{ overdueRequestsNumber }}</span>
                  </span>
                </div>
                <span>Overdue<br>Today</span>
              </router-link>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { GET_QUERY, API_SERVICE_REQUESTS } from "@/core/store/crud.module";

export default {
  name: "ServiceRequestsWidget",
  data: () => ({
    openRequestsNumber: 0,
    overdueRequestsNumber: 0,
    inProcessRequestsNumber: 0,
    show: false,
    loading: false,
    customerId: 0
  }),
  watch: {
    $route: async function(to) {
      // this.show = to.name !== "CustomerDetails";
      // console.log(to);
      if (to.name == "RedirectToCustomer") return;

      let newCustomerId = 0;
      if (to.params?.customerId) {
        newCustomerId = to.params?.customerId;
      }

      if (this.customerId != newCustomerId) {
        this.customerId = newCustomerId;
        this.openRequestsNumber = 0;
        this.overdueRequestsNumber = 0;
        await this.getServiceRequestsNumbers(this.customerId);
      }
    }
  },
  async mounted() {
    this.getShow();
    if (this.$route.to) {
      if (this.$route.to.params?.customerId) {
        this.customerId = this.$route.to.params?.customerId;
      }
    } else if (this.$route.from) {
      if (this.$route.from.params?.customerId) {
        this.customerId = this.$route.from.params?.customerId;
      }
    } else {
      if (this.$route.params?.customerId) {
        this.customerId = this.$route.params?.customerId;
      }
    }
    await this.getServiceRequestsNumbers(this.customerId);
  },
  methods: {
    getServiceRequestsPageUrl() {
      if (this.customerId != 0 && this.customerId != 712473) {
        return `/customers/${this.customerId}/requests`;
      }
      return "/service-requests";
    },
    getShow() {
      this.show = true;
      // if (this.$route.to) this.show = this.$route.to.name !== "CustomerDetails";
      // else if (this.$route.from)
      //   this.show = this.$route.from.name !== "CustomerDetails";
      // else this.show = this.$route.name !== "CustomerDetails";
      //console.log(this.$route);
    },

    async getServiceRequestsNumbers(customerId) {
      if (this.loading) return;

      this.loading = true;
      let self = this;

      let url = `${API_SERVICE_REQUESTS}/User/0/numbers`;
      if (customerId != 0) {
        url = `${API_SERVICE_REQUESTS}/Community/${customerId}/numbers`;
      }

      await this.$store
        .dispatch(GET_QUERY, {
          listName: `${url}`
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            let numbers = response.data;

            self.openRequestsNumber = numbers.open;
            self.inProcessRequestsNumber = numbers.inProcess;
            self.overdueRequestsNumber = numbers.overdueToday;
          }

          self.loading = false;
        })
        .catch(err => {
          this.$snackbar.showMessage({
            content: err
          });
          self.loading = false;
        });
    }

    // async getServiceRequestsNumbers(customerId) {
    //   if (this.loading) return;

    //   this.loading = true;
    //   let self = this;

    //   let url = `${API_SERVICE_REQUESTS}/User/0`;
    //   if (customerId != 0) {
    //     url = `${API_SERVICE_REQUESTS}/Community/${customerId}`;
    //   }

    //   await this.$store
    //     .dispatch(GET_QUERY, {
    //       listName: `${url}`
    //     })
    //     .then(response => {
    //       if (response.status >= 200 || response.status <= 204) {
    //         let items = response.data.$values;

    //         let date = new Date();

    //         for (let i = 0; i < items.length; i++) {
    //           let elem1 = items[i];
    //           let elemDate = new Date(elem1.targetCompletionDate);
    //           if (!elem1.isCompleted) {
    //             self.openRequestsNumber += 1;

    //             if (
    //               (elemDate.getFullYear() <= date.getFullYear() &&
    //                 elemDate.getMonth() <= date.getMonth() &&
    //                 elemDate.getDate() < date.getDate()) ||
    //               (elemDate.getFullYear() <= date.getFullYear() &&
    //                 elemDate.getMonth() < date.getMonth()) ||
    //               elemDate.getFullYear() < date.getFullYear()
    //             )
    //               self.overdueRequestsNumber += 1;
    //           }
    //         }
    //       }

    //       self.loading = false;
    //     })
    //     .catch(err => {
    //       this.$snackbar.showMessage({
    //         content: err
    //       });
    //       self.loading = false;
    //     });
    // }
  }
};
</script>

<style lang="scss" scoped>
@import "~/src/assets/sass/custom/custom.scss";

.orange-bg {
  background-color: $orange;
}
.service-requests-widget {
  border: solid 2px $orange;
}

.theme--light.v-card.service-requests-widget {
  background-color: transparent;
}

.theme--light.v-card.service-requests-widget::before {
  content: "";
  background-color: white;
  opacity: 0.8;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.service-requests-widget .orange-bg.rounded-circle {
  width: 60px;
  height: 60px;
  /* line-height: 45px;
  vertical-align: middle; */
}
</style>
